<div class="page-content fade-in-up">




    <div class="row mb-2 mt-2 justify-content-between">

        <div class="col-sm-3 pl-0 pr-0">
            <div class="mobile-mb-2 input-group-icon input-group-icon-left">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-templates" class="form-control" [(ngModel)]="code_filter" [ngbTypeahead]="search" type="text" placeholder="Tìm hóa đơn" [resultTemplate]="rt" [inputFormatter]="inputFormatter">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="row" (click)="onClick(r)" style="width: 450px;">
                    <div class="col"> #{{ r['code'] }}</div>
                    <div class="col">
                        <ngb-highlight [result]="r.name" [term]="t">
                        </ngb-highlight>
                    </div>
                    <div class="col">{{r['price_totol'] | qbscurrency}} VNĐ</div>
                    <div class="col">{{r['customer_name']}}</div>
                    <!-- <div class="col"> - {{ (r['created_date']  )}}</div> -->
                </div>
            </ng-template>
        </div>


        <div class="col-sm-9 pr-0">
            <div class="d-flex justify-content-end">
                <div class="pr-4">
                    <div class="btn-group">
                        <div class="btn btn-outline-primary" [ngClass]="{
                        'status-active': item.code == sale_status
                    }" (click)="sale_status_click(item)" *ngFor="let item of appService.sale_statuss; let i = index">
                            {{item.name}}
                        </div>
                    </div>

                    <!-- <select class="custom-select" [(ngModel)]="sale_status" (ngModelChange)="sale_status_change()">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sale_statuss">{{item.name}}
                        </option>
                    </select> -->

                </div>
                <div class="pr-2">
                    <select class="custom-select" [(ngModel)]="userNvbhSelected" (change)="userSelected_change()">
                        <option value="">Nhân viên : All</option>
                        <option [ngValue]="item.id" *ngFor="let item of userNvbhList">{{item.name}}</option>
                    </select>
                </div>
                <div class="pr-2">
                    <select class="custom-select" [(ngModel)]="day_num" (ngModelChange)="day_num_change()">
                        <option [ngValue]="item.code" *ngFor="let item of appService.day_nums">{{item.name}}</option>
                    </select>
                </div>


                <div class="">
                    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon " (click)="picker.open()">
                        <span class="btn-label "><span class="material-icons font-18">
                                today
                            </span></span>{{fromDateStr}}
                    </button>

                    <mat-form-field appearance="fill" style="display: none;">
                        <input matInput [matDatepicker]="picker" (dateChange)="dateChangeEvent($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <!-- <app-range-time-picker class="desktop" (click2)="onClick_selectDate($event)"></app-range-time-picker> -->

            </div>
        </div>
    </div>

    <ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">
        <li class="nav-item" *ngFor="let item of salesTitleByday_result; let i = index">
            <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.dateStr == this.selectedDateStr}" (click)="selectDate(item.dateStr)">
                <div style="font-size: large;">{{item.day_month}}</div>
                <div *ngIf="item.sum_price>0">{{item.sum_price | qbscurrency:0}}<img style="width: 15px;" src="./assets/img/icons/vnd.svg"></div>
                <div *ngIf="item.sum_price==0">---</div>
            </a>
        </li>
    </ul>
    <div class="tab-content">
    </div>

    <div style="margin: 8px 0px 3px 0px; font-size: 17px; font-weight: bold;">
        <span color="primary">Hóa đơn</span> : {{sales.length}}
        <!-- <ion-label color="primary">-</ion-label> : {{sales_sum}} VNĐ -->

        <!-- <div class="btn-group">
    <button class="btn btn-secondary">
        <span class="btn-icon"><i class="ti-heart"></i>Hóa đơn</span>
    </button>
    <span class="btn-label-out btn-label-out-right">{{sales.length}}</span>
</div> -->

    </div>


    <div class="d-flex flex-wrap mb-5">
        <div class="mb-2 cart-item-gird" [style.width.px]="appService.itemCartWidth_order" style="margin-right: 6px;" *ngFor="let item of sales; let i = index" (click)="popupSaleDetail(item)" [ngClass]="{'cart-done': item.status == 1,'cart-ordering': item.status == 0,'cart-cancel': item.status == 3,'cart-processing': item.status == 4}">
            <!-- <div class="rel" style="height: 50px;"> -->
            <!-- <img class="card-img-top" src="./assets/img/invoice.svg" alt="image" style="height: 100px;" /> -->
            <div class="card-header">
                <div class="row align-items-center justify-content-end">
                    <span [ngClass]="item.price_totol == item.price_after_promotion ? 'noPromotion' : 'hasPromotion'">
                        {{item.price_totol | qbscurrency:0}}
                    </span>
                </div>
                <div class="row align-items-center justify-content-end">
                    <span style=" font-weight: bold;">{{item.price_after_promotion | qbscurrency:0}}</span>
                    <img style="width: 16px;" src="./assets/img/icons/vnd.svg">
                </div>
            </div>
            <!-- </div> -->
            <div class="card-body">
                <div style="  white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; text-align: center;">{{item.customer_name}}</div>
                <div class="d-flex align-items-center justify-content-between mt-2">
                    <div class="text-center">
                        <div style="font-size: 12px;">
                            {{item.product_quantity}} / {{item.quantity}}</div>
                        <!-- <div class="text-muted font-11">Mặt hàng / SL</div> -->
                    </div>
                    <div style="font-size: 12px;">
                        <span *ngIf="item.customer_pay == item.price_after_promotion" class="badge-primary badge-point"></span>
                        <span *ngIf="item.customer_pay != item.price_after_promotion" class="badge-danger badge-point"></span> {{item.created_date.substring(11, 16)}}<i class="ti ti-time" style="font-size: 10px;"></i>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="this.sales.length == 0" style="height: 100%;">
        <app-nodata></app-nodata>
    </div>

    <div class="row align-items-center mb-2  paging-cover">
        <div class="col-4">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>






    <div class="modal fade" id="select-nvgh-modal">
        <div class="modal-dialog" role="document">
            <form class="modal-content form-horizontal modal-content-lg" id="select-nvgh-form">
                <div class="modal-header pb-4">
                    <h5 class="modal-title">Chọn nhân viên giao hàng</h5>
                    <a data-dismiss="modal"><i class="ti ti-close"></i></a>
                </div>
                <div class="modal-body p-3">
                    <div class=" p-2 mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                        <span class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                        <input class="form-control" id="key-search" (keydown)="getTypingSearchNvgh($event)" type="text" placeholder="Tìm nhân viên hàng">
                    </div>



                    <div class="mb-2 mt-2">
                        <span class=" item-nvbh" *ngFor="let item of memberships; let i = index">
                            <label class="radio radio-inline radio-primary">
                                <input type="radio" name="e" [value]="item.id" (click)="nvbhSelected = item;"
                                    [checked]="item === nvbhSelected">
                                <span class="input-span"></span>{{item.name}}
                        </label>
                        </span>
                    </div>

                </div>
                <div class="modal-footer bg-primary-50">

                    <div class="row ">
                        <div class="col ">
                            <button class="btn btn-primary btn-labeled btn-labeled-left btn-sm btn-icon" (click)="doShipping()">
                                <span class="btn-label"><i class="ti ti-truck"></i></span>
                                Giao hàng
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>

    <div class="modal fade" id="confirmDelete-modal">
        <div class="modal-dialog" role="document">
            <form class="modal-content form-horizontal modal-content-lg" id="confirmDelete-modal-form">
                <div class="modal-header pb-4">
                    <h5 class="modal-title">Nhập lý do hủy đơn</h5>
                    <a data-dismiss="modal"><i class="ti ti-close"></i></a>
                </div>
                <div class="modal-body p-3">
                    <div class="form-group">
                        <label class=" col-form-label">Mô tả</label>
                        <div class="">
                            <input [(ngModel)]="cancel_reason" required class="form-control" name="description" placeholder="Lý do" type="text">
                            <!-- <textarea class="form-control" [(ngModel)]="product.description" rows="3" placeholder="Mô tả"></textarea> -->
                        </div>
                    </div>

                </div>
                <div class="modal-footer justify-content-around bg-primary-50">
                    <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading" (click)="confirmDeleteSale()">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                        Hủy đơn
                    </button>

                    <button class="btn" data-dismiss="modal">Đóng</button>
                </div>

            </form>
        </div>
    </div>

    <!-- <ng-template #confirmDelete let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Nhập lý do hủy đơn</h4>
          
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label class=" col-form-label">Mô tả</label>
                <div class="">
                    <input [(ngModel)]="cancel_reason" required class="form-control" name="description" placeholder="Lý do" type="text">
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-around bg-primary-50">
            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading" (click)="confirmDeleteSale()">
                <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                Hủy đơn
            </button>

            <button class="btn" (click)="c('Save click')">Đóng</button>
        </div>
     
    </ng-template> -->


</div>